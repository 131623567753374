import { ReactNode } from "react";

interface StyleComponentProps {
  children: ReactNode;
}

const StyleComponent: React.FC<StyleComponentProps> = ({ children }) => {
  const containerStyle: React.CSSProperties = {  
    marginTop:"40px",
};



  return <div style={containerStyle}>{children}</div>;
};

export default StyleComponent;
