import { useEffect, useState } from "react";
import { Box, Container,Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import DJCodeBlock, { ICodeBlock } from "../../main/components/codeblock";
import { IProgress, ISessionProps } from "../../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@appsflyer/fe-ui-core";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import transition from "../../main/components/transition";
import { amplitudeTrack_continue } from "../../services/utils";

const DL_implementAndroid = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [progress, setProgress] = useState<IProgress[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [extractedDomain, setExtractedDomain] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      setProgress(state?.progress);
    }
    const onelink: URL = new URL(
      location.state.oneLinkURL.indexOf("http") > -1
        ? location.state.oneLinkURL
        : "https://" + location.state.oneLinkURL
    );
    setExtractedDomain(onelink.host);
  }, [location.state]);

  const handleClick = () => {
    const progressArr: IProgress[] = progress.slice();
    progressArr.push({
      id: "dl_dltype",
      text: "progress_DL_type",
      order: 4,
    });
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr, 
      currentPage: location.state?.currentPage + 1,
    };
    amplitudeTrack_continue(location.state);

    navigate("/deeplink/android/deeplinktype", { state: progressState });
  };

  const code_implement = `import com.appsflyer.deeplink.DeepLink;
import com.appsflyer.deeplink.DeepLinkListener;
import com.appsflyer.deeplink.DeepLinkResult;
public class ExampleApp extends application {
   @Override
   public void onCreate() {
     super.onCreate();
     ...   
   }
}`;

  const codeBlockProps_implement: ICodeBlock = {
    code: code_implement,
    language: "java", // Assuming it's Java, change it to the correct language if needed
    showLineNumbers: true,
    highlight: "1-3",
  };

  const code4 = `public class ExampleApp extends application {
   @Override
   public void onCreate() {
     super.onCreate();
     ...
     AppsFlyerLib appsflyer = AppsFlyerLib.getInstance();
     ...   
   }
}`;

  const codeBlockProps4: ICodeBlock = {
    code: code4,
    language: "java", // Assuming it's Java, change it to the correct language if needed
    showLineNumbers: true,
    highlight: "6",
  };

  const code5 = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      AppsFlyerLib appsflyer = AppsFlyerLib.getInstance();
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
        // TODO - will be implemented in the following steps 
      }
      ...   
    }
 }`;

  const codeBlockProps5: ICodeBlock = {
    code: code5,
    language: "java", // Assuming it's Java, change it to the correct language if needed
    showLineNumbers: true,
    highlight: "7"
  };

  const code6 = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      AppsFlyerLib appsflyer = AppsFlyerLib.getInstance();
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
         @Override
         public void onDeepLinking(@NonNull DeepLinkResult deepLinkResult) {
           // TODO - will be implemented in the following steps.
         }
      }
      ...   
    }
 }`;

  const codeBlockProps6: ICodeBlock = {
    code: code6,
    language: "java", // Assuming it's Java, change it to the correct language if needed
    showLineNumbers: true,
    highlight: "7-9"
  };

  const code7 = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      AppsFlyerLib appsflyer = AppsFlyerLib.getInstance();
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
         @Override
         public void onDeepLinking(@NonNull DeepLinkResult deepLinkResult) {
             DeepLinkResult.Status dlStatus = deepLinkResult.getStatus();
             if (dlStatus == DeepLinkResult.Status.FOUND) {
                 // your code for deeplink found
             } else if (dlStatus == DeepLinkResult.Status.NOT_FOUND) {
              // your code for deeplink not found
                 return;
             } else {
                 // dlStatus == DeepLinkResult.Status.ERROR
                 DeepLinkResult.Error dlError = deepLinkResult.getError();
                 // your code for error handling
                 return;
             }
         }
      }
 }`;

  const codeBlockProps7: ICodeBlock = {
    code: code7,
    language: "java", // Assuming it's Java, change it to the correct language if needed
    showLineNumbers: true,
    highlight: "10-21"
  };

  const code8 = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      AppsFlyerLib appsflyer = AppsFlyerLib.getInstance();
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
         @Override
         public void onDeepLinking(@NonNull DeepLinkResult deepLinkResult) {
           // See "Retrieve UDL status" step - TBA think how to squeeze this
           DeepLink deepLinkObj = deepLinkResult.getDeepLink();
           try {
             // your code for handling the deeplink data - deepLinkObj.toString()
           } catch (Exception e) {
             // your code for handling null deeplink data
             return;
           }
         }
      }
 }`;

  const codeBlockProps8: ICodeBlock = {
    code: code8,
    language: "java", // Assuming it's Java, change it to the correct language if needed
    showLineNumbers: true,
    highlight: "11-17"
  };

  const code9 = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      AppsFlyerLib appsflyer = AppsFlyerLib.getInstance();
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
         @Override
         public void onDeepLinking(@NonNull DeepLinkResult deepLinkResult) {
           // See "Retrieve UDL status" step - TBA think how to squeeze this
           // See "Retrieve DeepLink object" step - TBA think how to squeeze this
           var deepLinkDestination = deepLinkObj.getDeepLinkValue();
           // your code for handling the deeplink route
         }
      }
 }`;

  const codeBlockProps9: ICodeBlock = {
    code: code9,
    language: "java", // Assuming it's Java, change it to the correct language if needed
    showLineNumbers: true,
    highlight: "10-13"
  };

  const code10 = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      AppsFlyerLib appsflyer = AppsFlyerLib.getInstance();
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
         @Override
         public void onDeepLinking(@NonNull DeepLinkResult deepLinkResult) {
           // See "Retrieve UDL status" step - TBA think how to squeeze this
           // See "Retrieve DeepLink object" step - TBA think how to squeeze this
 
           if (deepLinkObj.isDeferred()) {
             // your code for deferred deep link
            } else {
             // your code for direct deep link
           }
         }
      }
      ...
    }`;

  const codeBlockProps10: ICodeBlock = {
    code: code10,
    language: "java", // Assuming it's Java, change it to the correct language if needed
    showLineNumbers: true,
    highlight: "10-17"
  };

  const steps = [
    {
      label: t("dl_page8_android_importCodeLibraries"),
      component: (
        <>
          <Typography>
            {t("dl_page8_android_importLibrariesInstructions")}
          </Typography>
          <br />
          <DJCodeBlock {...codeBlockProps_implement} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page9_android_step1Label"),
      component: (
        <>
          <br />
          <DJCodeBlock {...codeBlockProps4} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page10_android_step2Label"),
      component: (
        <>
          <br />
          <DJCodeBlock {...codeBlockProps5} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page11_android_step3Label"),
      component: (
        <>
          <Typography>
            {t("dl_page11_android_onDeepLinkingInstructions")}
            <code> onDeepLinking() </code>
            {t("dl_page11_android_onDeepLinkingInstructions_b")}
            <code> onDeepLinking() </code>
            {t("dl_page11_android_onDeepLinkingInstructions_c")}
            <code> DeepLinkResult </code>
            {t("dl_page11_android_onDeepLinkingInstructions_d")}
            <code> onDeepLinking()</code>.
          </Typography>
          <br />
          <DJCodeBlock {...codeBlockProps6} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page12_android_step4Label"),
      component: (
        <>
          <Typography>
            {t("dl_page12_android_retrieveUDLStatusInstructions")}
            <code> getStatus() </code>
            {t("dl_page12_android_retrieveUDLStatusInstructions_b")}
          </Typography>
          <br />

          {/* Use DJCodeBlock for code formatting */}
          <DJCodeBlock {...codeBlockProps7} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page13_android_step5Label"),
      component: (
        <>
          <Typography>
            {t("dl_page13_android_retrieveDeepLinkObjectInstructions")}
            <code> getDeepLink() </code>
            {t("dl_page13_android_retrieveDeepLinkObjectInstructions_b")}
          </Typography>
          <br />

          {/* Use DJCodeBlock for code formatting */}
          <DJCodeBlock {...codeBlockProps8} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page14_android_step6Label"),
      component: (
        <>
          <Typography>
            {t("dl_page14_android_useDeepLinkValueInstructions")}
            <code> deep_link_value </code>
            {t("dl_page14_android_useDeepLinkValueInstructions_b")}
            <code> deep_link_value </code>
            {t("dl_page14_android_useDeepLinkValueInstructions_c")}
            <code> getDeepLinkValue() </code>
            {t("dl_page14_android_useDeepLinkValueInstructions_d")}
          </Typography>
          <br />

          {/* Use DJCodeBlock for code formatting */}
          <DJCodeBlock {...codeBlockProps9} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page15_android_step7Label"),
      component: (
        <>
          <Typography>
            <code>isDeferred() </code>
            {t("dl_page15_android_optionalCheckInstructions")}
          </Typography>
          <br />

          {/* Use DJCodeBlock for code formatting */}
          <DJCodeBlock {...codeBlockProps10} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];
  
  // add branded domain step only if URL does not include onelink.me
  if (extractedDomain.indexOf("onelink.me") == -1) {
    const code_branded = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      AppsFlyerLib appsflyer = AppsFlyerLib.getInstance();
      void setOneLinkCustomDomain("${extractedDomain}")
      ...   
    }
 }`;

    const codeBlockProps_branded: ICodeBlock = {
      code: code_branded,
      language: "java", // Assuming it's Java, change it to the correct language if needed
      showLineNumbers: true,
      highlight: "7",
    };
    steps.splice(2, 0, {
      label: t("dl_brandedDomains_label"),
      component: (
        <>
          {/* Use DJCodeBlock for code formatting */}
          <DJCodeBlock {...codeBlockProps_branded} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    });
  }

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} marginBottom={-10} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          <Typography variant="h1">
            {t("dl_page9_android_pageTitle")}
          </Typography>
          <Stepper
            activeStep={activeStep}
            style={{ border: "none" }}
            orientation="vertical"
          >
            {steps.map(
              ({ label, component, shouldDisplayNavigationButtons }) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {component}
                    {shouldDisplayNavigationButtons && (
                      <Box sx={{ mt: 2 }}>
                        {activeStep !== 0 && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={handleBack}
                            // sx={{ mt: 2 }}
                          >
                            {t("general_Back")}
                          </Button>
                        )}
                        {activeStep !== steps.length - 1 && (
                          <Button
                            // sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            {activeStep === steps.length - 1
                              ? t("general_Finish")
                              : t("general_Next")}
                          </Button>
                        )}
                      </Box>
                    )}
                  </StepContent>
                </Step>
              )
            )}
          </Stepper>
        </Box>
      </Box>

      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(DL_implementAndroid);
