import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, useMediaQuery, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { IProgress, ISessionProps } from "../../main/components/IProgress";
import transition from "../../main/components/transition";
import { TextField, Typography } from "@appsflyer/fe-ui-core";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel"; 
import StepContent from "@mui/material/StepContent";
import { amplitudeTrack_continue, amplitudeTrack_inputChange, InputType } from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";

const DL_prepareURI = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [progress, setProgress] = useState<IProgress[]>([]);
  const [uriScheme, setUriScheme] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const uriRegex = /^[a-zA-Z][a-zA-Z0-9+\-.]*:/;

  const testUri = (scheme: string) => {
    if (scheme.indexOf("http") > -1) return false;
    return uriRegex.test(scheme);
  };

  useEffect(() => {
    const uriScheme = localStorage.getItem(
      `uriScheme_${location.state?.os}_${location.state?.appID}`
    );
    if (uriScheme) {
      setUriScheme(JSON.parse(uriScheme));
    }
  }, []);

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      setProgress(state?.progress);
    }
  }, [location.state]);

  const handleClick = () => {
    const progressArr: IProgress[] = progress.slice();
    progressArr.push({
      id: "dl_implementCode",
      text: "progress_implement_code",
      order: 3,
    });
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };

    if (!uriScheme || testUri(uriScheme)) {
      localStorage.setItem(
        `uriScheme_${location.state?.os}_${location.state?.appID}`,
        JSON.stringify(uriScheme)
      );
      if (uriScheme) progressState.uriScheme = uriScheme;
    }
    amplitudeTrack_continue(location.state);

    navigate("/deeplink/ios/implement", { state: progressState });
  };

  const steps = [
    {
      label: t("dl_7_ios_step_1_substep_title_a"),
      component: (
        <>
          <Typography>{t(`dl_7_ios_step_1_substep_a`)}</Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_7_ios_step_1_substep_title_b"),
      component: (
        <>
          <Typography>
            {t(`dl_7_ios_step_1_substep_2_a`)} <b>URL type</b>{" "}
            {t(`dl_7_ios_step_1_substep_2_b`)}
          </Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_7_ios_step_1_substep_title_c"),
      component: (
        <>
          <Typography>
            {t(`dl_7_ios_step_1_substep_3_a`)} <b>URL type</b>{" "}
            {t(`dl_7_ios_step_1_substep_3_b`)} <b>Item 0</b>{" "}
            {t(`dl_7_ios_step_1_substep_3_c`)}
          </Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_7_ios_step_1_substep_title_d"),
      component: (
        <>
          <Typography>{t(`dl_7_ios_step_1_substep_d`)}</Typography>
          <Typography>{t(`dl_7_ios_step_1_substep_e`)}</Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_7_ios_step_1_substep_title_f"),
      component: (
        <>
          <Typography>
            {t(`dl_7_ios_step_1_substep_6_a`)} <b>URL identifier</b>{" "}
            {t(`dl_7_ios_step_1_substep_6_b`)} <b>Add Row {">"} URI Schemes</b>
          </Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_7_ios_step_1_substep_title_g"),
      component: (
        <>
          <Typography>
            {t(`dl_7_ios_step_1_substep_7_a`)} <b>Item 0</b>{" "}
            {t(`dl_7_ios_step_1_substep_7_b`)}
          </Typography>{" "}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  const notMobile = useMediaQuery("(min-width:800px)");

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          <Typography variant="h1">{t("dl_7_ios_prepare_app")}</Typography>
        </Box>

        <Typography variant="h2" gutterBottom>
          {t("dl_7_ios_optional_uri_scheme")}
        </Typography>

        <Typography variant="h3" gutterBottom>
          {t("dl_7_ios_step_a")}
        </Typography>

        <Box
          // p={2}
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems={"flex-start"}
          flexDirection={"column"}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <TextField
              label={t("dl_4_ios_uri_scheme_label")}
              value={uriScheme}
              placeholder={"myappuri://launch"}
              inputProps={{ className: "amp-unmask" }}
              onChange={(e) => {
                setUriScheme(e.currentTarget.value);
                amplitudeTrack_inputChange(
                  location.state,
                  InputType.TextField,
                  "uriScheme",
                  e.target.value
                );
              }}
              variant="outlined"
              error={uriScheme.length > 0 && !testUri(uriScheme)}
              size={notMobile ? "xl" : ""}
              style={!notMobile ? { maxWidth: "300px" } : {}}
            />
          </Box>
          <AmplitudeLink
            my={2}
            href={`https://${
              location.state.lang != "en" ? location.state.lang + "." : ""
            }dev.appsflyer.com/hc/docs/dl_ios_init_setup#procedures-for-uri-scheme`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("dl_4_ios_uri_scheme_link")}
          </AmplitudeLink>
        </Box>

        {uriScheme && testUri(uriScheme) && (
          <Stepper
            activeStep={activeStep}
            style={{ border: "none" }}
            orientation="vertical"
          >
            {steps.map(
              ({ label, component, shouldDisplayNavigationButtons }) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {component}
                    {shouldDisplayNavigationButtons && (
                      <Box sx={{ mt: 2 }}>
                        {activeStep !== 0 && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={handleBack}
                            // sx={{ mt: 2, mr: 1 }}
                          >
                            {t("general_Back")}
                          </Button>
                        )}
                        {activeStep !== steps.length - 1 && (
                          <Button
                            // sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            {activeStep === steps.length - 1
                              ? t("general_Finish")
                              : t("general_Next")}
                          </Button>
                        )}
                      </Box>
                    )}
                  </StepContent>
                </Step>
              )
            )}
          </Stepper>
        )}
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("StartSDK_back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("StartSDK_continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(DL_prepareURI);
