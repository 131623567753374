import { TextField, Typography } from "@appsflyer/fe-ui-core";
import { useTranslation } from "react-i18next";
import { ISessionProps } from "../../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Container,
  FormControl,
  FormHelperText,
  Snackbar,
  useMediaQuery,
  Button,
} from "@mui/material";
import transition from "../../main/components/transition";
import { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import {
  amplitudeTrack_continue,
  amplitudeTrack_inputChange,
  checkOnelinkRegex,
  InputType,
} from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";

const DL_prepareUniversalLinks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [oneLinkURL, setOneLinkURL] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const notMobile = useMediaQuery("(min-width:800px)");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const oneLinkURL = localStorage.getItem(
      `oneLinkURL_${location.state?.os}_${location.state?.appID}`
    );
    if (oneLinkURL) {
      setOneLinkURL(JSON.parse(oneLinkURL));
    }
  }, []);

  const handleCloseSnackbar = (event: Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleClick = () => {
    const progressState: ISessionProps = {
      ...location.state,
      currentPage: location.state?.currentPage + 1,
    };

    if (!oneLinkURL || !checkOnelinkRegex(oneLinkURL)) {
      setSnackbarOpen(true);
      setSnackbarMessage("Please fill a valid OneLink URL");
      return;
    } else {
      localStorage.setItem(
        `oneLinkURL_${location.state?.os}_${location.state?.appID}`,
        JSON.stringify(oneLinkURL)
      );
      progressState.oneLinkURL = oneLinkURL;
    }
    amplitudeTrack_continue(location.state);

    navigate("/deeplink/ios/prepare2", { state: progressState });
  };

  const steps = [
    {
      label: t("dl_6_ios_prerequisites_a"),
      component: <></>,
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_6_ios_prerequisites_b_title"),
      component: (
        <>
          <AmplitudeLink
            href={`https://${
              location.state.lang != "en" ? location.state.lang + "." : ""
            }dev.appsflyer.com/hc/docs/dl_ios_init_setup#getting-the-app-bundle-id-and-prefix-id`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("dl_6_ios_prerequisites_b")}
          </AmplitudeLink>{" "}
          {t("dl_6_ios_prerequisites_c")}
          <br />
          <br />
          <img
            style={{ width: "500px", display: "flex", alignSelf: "center" }}
            src={process.env.PUBLIC_URL + "/images/certs_apple_info.png"}
            alt={t("homepage_logoAlt")}
          />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_6_ios_prerequisites_c_title"),
      component: (
        <>
          {t("dl_6_ios_prerequisites_d")}{" "}
          <AmplitudeLink
            href={`https://${
              location.state.lang != "en" ? location.state.lang + "." : ""
            }dev.appsflyer.com/hc/docs/dl_ios_init_setup#getting-the-app-bundle-id-and-prefix-id:~:text=the%20iOS%20instructions%20for%20associated%20domains`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("dl_6_ios_prerequisites_e")}
          </AmplitudeLink>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_6_ios_prerequisites_d_title"),
      component: (
        <>
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <FormControl
                error={!oneLinkURL || !checkOnelinkRegex(oneLinkURL)}
              > 
                <TextField
                  required={true}
                  label={t("dl_4_ios_one_link_label_ios")}
                  value={oneLinkURL}
                  inputProps={{ className: "amp-unmask" }}
                  onChange={(e) => {
                    setOneLinkURL(e.currentTarget.value);
                    amplitudeTrack_inputChange(
                      location.state,
                      InputType.TextField,
                      "oneLinkURL",
                      e.target.value
                    );
                  }}
                  placeholder={
                    "https://[Your-Domain.com]/[XXXX]/.... - for example: https://onelink-sim.com/ObO1/b081iy47"
                  }
                  variant="outlined"
                  size={notMobile ? "xl" : ""}
                  style={!notMobile ? { maxWidth: "200px" } : {}}
                />
                <FormHelperText id="component-error-text">
                  {t("startSDK_appParameters_android_required")}
                </FormHelperText>
              </FormControl>
            </Box>
            <AmplitudeLink
              href="https://support.appsflyer.com/hc/en-us/articles/208874366-OneLink-links-and-experiences"
              target="_blank"
              rel="noopener noreferrer"
              mx={2}
              mb={1}
            >
              {t("dl_4_ios_ask_marketer_link")}
            </AmplitudeLink>
          </Box>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          <Typography variant="h1">{t("dl_6_ios_prepare_app")}</Typography>
        </Box>

        <Typography variant="h2" gutterBottom>
          {t("dl_6_ios_universal_links")}
        </Typography>

        <Typography variant="h3" gutterBottom>
          {t("dl_6_ios_prerequisites")}
        </Typography>

        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        >
          <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={5}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("StartSDK_back")}
        </Button>
        <Button
          disabled={!oneLinkURL || !checkOnelinkRegex(oneLinkURL)}
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("StartSDK_continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(DL_prepareUniversalLinks);
