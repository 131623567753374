import * as React from "react";
import { Box, Button, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ISessionProps } from "../../main/components/IProgress";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import { amplitudeTrack_continue } from "../../services/utils";

const DL_testDeferredIOS = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    const progressState: ISessionProps = {
      ...location.state,
      currentPage: location.state?.currentPage + 1,
    };
    amplitudeTrack_continue(location.state);

    navigate("/deeplink/ios/testdeferreddl", { state: progressState });
  };
  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          <Typography variant="h1">{t("dl_19_ios_test_deferred_linking")}</Typography>
        </Box>

        <Typography variant="h2">{t("dl_18_ios_prerequisites")}</Typography>

        <li>
          <a
            href="http://www.link_to_code_integration.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("dl_18_ios_complete_udl_integration")}
          </a>
        </li>
        <li>
          <a
            href="http://www.link_to_enable_debug_logs.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("dl_18_ios_register_testing_device")}
          </a>
        </li>
        <li>
          <a
            href="http://www.link_to_debug_mode.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("dl_page16_android_enableDebugMode")}
          </a>
        </li>
        <li>{t("dl_page16_android_ensureAppNotInstalled")}</li>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("StartSDK_back")}
        </Button>
        <Button variant="contained" size="medium" onClick={() => handleClick()}>
          {t("StartSDK_continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(DL_testDeferredIOS);