/* eslint-disable */
import { Box, Container } from "@mui/material";
import Alert from '@mui/material/Alert';
import DJCodeBlock, { ICodeBlock } from "../main/components/codeblock";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { Typography } from "@appsflyer/fe-ui-core";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import {
  IProgress,
  ISessionProps,
  IntegrationPathType,
  OSType,
  SDKTestType,
  SessionStatus,
} from "../main/components/IProgress";
import transition from "../main/components/transition";
import { Button, Snackbar } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { testConsoleOnelinkSig } from "../services/api";
import {
  amplitudeTrack_continue,
  creatDLObject as createDLObject,
  sendGtagOnce,
} from "../services/utils";
import AmplitudeLink from "../services/AmplitudeLink";

const DL_testDirectManual = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [QRvalue, setQRValue] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [severity, setSeverity] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [jsonEventsShortLink, setJsonEventsShortLink] = useState({});

  const setDoneAndGoBack = () => {
    if (location.state?.sdkTestType === SDKTestType.Manual) {
      localStorage.setItem(
        `isDirectDone_${location.state?.os}_${location.state?.appID}`,
        "true"
      );
      const progressState: ISessionProps = {
        ...location.state,
        isDirectDone: true,
        sessionStatus: SessionStatus.Success,
      };

      // send gtag for manual testing success
      sendGtagOnce(
        "manual_test_success_fixed",
        "DL_testDirectManual",
        location.state.sessionId
      );

      amplitudeTrack_continue(location.state);

      navigate(`/deeplink/${location.state?.os}/deeplinktype`, {
        state: progressState,
      });
    } else {
      const progressArr: IProgress[] = location.state?.progress.slice();
      // progressArr.push({
      //   id: "dl_testevents",
      //   text: "Get Results",
      //   order: Math.max(...progressArr.map((o) => o.order)) + 1,
      // });

      const progressState: ISessionProps = {
        ...location.state,
        progress: progressArr,
        currentPage: location.state?.currentPage + 1,
      };

      navigate(`/deeplink/${location.state?.os}/eventstestconsole`, {
        state: progressState,
      });
    }
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseSnackbar = (
    event: SyntheticEvent<Element, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };

  const codePropsShortlink: ICodeBlock = {
    code: `[DDL] Calling onDeepLinking with: 
{"deepLink":"${JSON.stringify(
      createDLObject(location.state, jsonEventsShortLink),
      null,
      2
    )}",
"status":"FOUND"}`,
    language: "java",
    showLineNumbers: true,
  };

  useEffect(() => {
    setQRValue(location.state?.oneLinkURL || "");

    const lsDevKey = localStorage.getItem("devKey");
    if (lsDevKey) {
      const dKey = JSON.parse(lsDevKey);

      if (
        (location.state as ISessionProps).path === IntegrationPathType.DeepLink
      ) {
        const onelink: URL = new URL(
          location.state.oneLinkURL.indexOf("http") > -1
            ? location.state.oneLinkURL
            : "https://" + location.state.oneLinkURL
        );
        const oneLinkId = onelink.pathname.split("/")[1];
        const shortLinkId = onelink.pathname.split("/")[2];

        testConsoleOnelinkSig(dKey, oneLinkId, shortLinkId)
          ?.then((res) => {
            if (res?.status === "Error") {
              console.log("onelink sig error:", res.data.error);
              setSeverity("error");
              setSnackbarMessage(res.data.error);
            } else if (res?.status === "Success") {
              setJsonEventsShortLink(res.data);
              setSeverity("success");
            } else {
              console.error("Unexpected response:", res);
              setSeverity("error");
              setSnackbarMessage("Unexpected response:" + res);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, []);

  const stepsIOS = [
    {
      label: t("testConsole_TestType_manualTest_desc_c"),
      component: (
        <>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>
              {t("startSDK_TestSDKIntegration_android_Step2") + ": "}
            </span>
            <AmplitudeLink
              target="_blank"
              href={
                location.state.os === OSType.Android
                  ? `https://${
                      location.state.lang != "en"
                        ? location.state.lang + "."
                        : ""
                    }dev.appsflyer.com/hc/docs/integrate-android-sdk#enabling-debug-mode`
                  : `https://${
                      location.state.lang != "en"
                        ? location.state.lang + "."
                        : ""
                    }dev.appsflyer.com/hc/docs/integrate-ios-sdk#enabling-debug-mode`
              }
            >
              {t("startSDK_TestSDKIntegration_android_EnableDebugMode")}
            </AmplitudeLink>
          </Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_androidtestdirect_android_step"),
      component: (
        <>
          <Typography>
            {t("dl_androidtestdirect_android_action") + " "}
            <AmplitudeLink href={QRvalue} target="_blank">
              {t("dl_androidtestdirect_android_action2")}
            </AmplitudeLink>
          </Typography>

          <QRCode
            size={256}
            style={{
              height: "auto",
              maxWidth: "25%",
              width: "25%",
              display: "block",
              margin: "20px auto",
            }}
            value={QRvalue}
            viewBox={`0 0 256 256`}
          />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_androidtestdirect2_ios_step"),
      component: (
        <>
          <Typography>{t("dl_androidtestdirect2_android_action")}</Typography>

          <Typography sx={{ mt: 4 }} variant="h2">
            <b>{t("dl_androidtestdirect2_android_subtitle_b")}</b>
          </Typography>

          <ul>
            <li>
              {t("dl_androidtestdirect2_android_li1")}
              <ul>
                <li>
                  <a
                    href={`https://${
                      location.state.lang != "en"
                        ? location.state.lang + "."
                        : ""
                    }dev.appsflyer.com/hc/docs/dl_ios_init_setup#enabling-associated-domains`}
                    target="_blank"
                  >
                    {t("dl_androidtestdirect2_ios_li1_a")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.appsflyer.com/hc/en-us/articles/208874366-OneLink-links-and-experiences"
                    target="_blank"
                  >
                    {t("dl_androidtestdirect2_android_li1_b")}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_androidtestdirect3_android_step"),
      component: (
        <>
          <Typography>
            {t("dl_androidtestdirect3_android_action") + " "}
          </Typography>

          <DJCodeBlock {...codePropsShortlink} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  const stepsAndroid = [
    {
      label: t("testConsole_TestType_manualTest_desc_c"),
      component: (
        <>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>
              {t("startSDK_TestSDKIntegration_android_Step2") + ": "}
            </span>
            <AmplitudeLink
              target="_blank"
              href={
                location.state.os === OSType.Android
                  ? `https://${
                      location.state.lang != "en"
                        ? location.state.lang + "."
                        : ""
                    }dev.appsflyer.com/hc/docs/integrate-android-sdk#enabling-debug-mode`
                  : `https://${
                      location.state.lang != "en"
                        ? location.state.lang + "."
                        : ""
                    }dev.appsflyer.com/hc/docs/integrate-ios-sdk#enabling-debug-mode`
              }
            >
              {t("startSDK_TestSDKIntegration_android_EnableDebugMode")}
            </AmplitudeLink>
          </Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_androidtestdirect_android_step"),
      component: (
        <>
          <Typography>
            {t("dl_androidtestdirect_android_action") + " "}
            <AmplitudeLink href={QRvalue} target="_blank">
              {t("dl_androidtestdirect_android_action2")}
            </AmplitudeLink>
          </Typography>

          <QRCode
            size={256}
            style={{
              height: "auto",
              maxWidth: "25%",
              width: "25%",
              display: "block",
              margin: "20px auto",
            }}
            value={QRvalue}
            viewBox={`0 0 256 256`}
          />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_androidtestdirect2_android_step"),
      component: (
        <>
          <Typography>{t("dl_androidtestdirect2_android_action")}</Typography>

          <Typography sx={{ mt: 4 }} variant="h2">
            <b>{t("dl_androidtestdirect2_android_subtitle_b")}</b>
          </Typography>

          <ul>
            <li>
              {t("dl_androidtestdirect2_android_li1")}
              <ul>
                <li>
                  <a
                    href={`https://${
                      location.state.lang != "en"
                        ? location.state.lang + "."
                        : ""
                    }dev.appsflyer.com/hc/docs/dl_android_init_setup`}
                    target="_blank"
                  >
                    {t("dl_androidtestdirect2_android_li1_a")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.appsflyer.com/hc/en-us/articles/208874366-OneLink-links-and-experiences"
                    target="_blank"
                  >
                    {t("dl_androidtestdirect2_android_li1_b")}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              {t("dl_androidtestdirect2_android_li2")}
              <ul>
                <li>
                  {t("dl_androidtestdirect2_android_li2_a") + " "}
                  <AmplitudeLink
                    href={`https://${
                      location.state.lang != "en"
                        ? location.state.lang + "."
                        : ""
                    }dev.appsflyer.com/hc/docs/dl_android_init_setup#procedures-for-android-app-links`}
                    target="blank"
                  >
                    {t("dl_androidtestdirect2_android_li2_a_link")}
                  </AmplitudeLink>
                </li>
              </ul>
            </li>
          </ul>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_androidtestdirect3_android_step"),
      component: (
        <>
          <Typography>
            {t("dl_androidtestdirect3_android_action") + " "}
          </Typography>

          <DJCodeBlock {...codePropsShortlink} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  const steps = location.state.os === OSType.Android ? stepsAndroid : stepsIOS;

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          <Typography variant="h1">
            {t("dl_androidtestdirect2_android_title")}
          </Typography>
        </Box>

        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <Snackbar
          open={isOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          style={{ position: "sticky" }}
        >
          <Alert
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          style={{ marginRight: "5px" }}
          color="secondary"
          onClick={() => navigate(-1)}
        >
          {t("StartSDK_back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={activeStep != steps.length - 1}
          onClick={() => setDoneAndGoBack()}
        >
          {location.state?.sdkTestType === SDKTestType.Manual &&
            t("dl_general_choose_dl")}
          {location.state?.sdkTestType === SDKTestType.Automated &&
            t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(DL_testDirectManual);
