import { Box, Container, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IProgress, ISessionProps } from "../../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import { amplitudeTrack_continue } from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";

const TestDeferredAndroid = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [progress, setProgress] = useState<IProgress[]>([]);

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      setProgress(state?.progress);
    }
  }, [location.state]);
  
  const handleClick = () => {
    const progressArr: IProgress[] = progress.slice();
    // progressArr.push({
    //   id: "app5",
    //   text: "Prepare app for direct deep linking",
    //   order: 2,
    // });
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };
    amplitudeTrack_continue(location.state);

    navigate("/deeplink/android/testdeferreddl", { state: progressState });
  };
  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          <Typography variant="h1">
            {t("dl_page16_android_pageTitle")}
          </Typography>
        </Box>

        {/* Prerequisites */}
        <Typography variant="h2">
          {t("dl_page16_android_prerequisitesLabel")}
        </Typography>

        <ul>
          <li>
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/dl_android_unified_deep_linking`}
              target="_blank"
            >
              <Typography variant="body1">
                {t("dl_page16_android_completeUDLLink")}
              </Typography>
            </AmplitudeLink>
          </li>
          <li>
            <AmplitudeLink
              href="https://support.appsflyer.com/hc/en-us/articles/207031996-Registering-test-devices-"
              target="_blank"
            >
              <Typography variant="body1">
                {t("dl_page16_android_registerDeviceLink")}
              </Typography>
            </AmplitudeLink>
          </li>
          <li>
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/integrate-android-sdk#enabling-debug-mode`}
              target="_blank"
            >
              <Typography variant="body1">
                {t("dl_page16_android_enableDebugMode")}
              </Typography>
            </AmplitudeLink> 
          </li>
          <li>
            <Typography variant="body1">
              {t("dl_page16_android_ensureAppNotInstalled")}
            </Typography>
          </li>
        </ul>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button variant="contained" size="medium" onClick={() => handleClick()}>
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(TestDeferredAndroid);
