import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DJCodeBlock from "../../main/components/codeblock";
import { useLocation, useNavigate } from "react-router";
import { ISessionProps } from "../../main/components/IProgress";
import { TextField, Typography } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import {
  amplitudeTrack_continue,
  amplitudeTrack_inputChange,
  checkOnelinkRegex,
  InputType,
} from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";

const DL_prepareAndroid = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [appId, setAppId] = useState<string>("");
  const [oneLinkURL, setOneLinkURL] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [code1, setCode1] = useState("");

  const oneLinkRegexWithoutHttp =
    /[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{4}/m;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseSnackbar = (event: Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const notMobile = useMediaQuery("(min-width:800px)");

  useEffect(() => {
    const oneLinkURL_ls = localStorage.getItem(
      `oneLinkURL_${location.state?.os}_${location.state?.appID}`
    );
    if (oneLinkURL_ls) {
      const parsedOneLink = JSON.parse(oneLinkURL_ls).replaceAll(`"`, ``);
      setOneLinkURL(parsedOneLink);
      const newSubdomain = parsedOneLink?.startsWith("http")
        ? new URL(parsedOneLink).hostname
        : new URL("http://" + parsedOneLink).hostname;

      setCode1(`<?xml version="1.0" encoding="utf-8"?>
<manifest xmlns:android="http://schemas.android.com/apk/res/android"
    xmlns:tools="http://schemas.android.com/tools"
    package="${appId}">
  <application
    android:theme="@style/AppTheme">
    <activity
      android:name=".MainActivity"
      android:exported="true"
      android:launchMode="singleTask">
      <intent-filter android:autoVerify="true">
        <action android:name="android.intent.action.VIEW" />
        <category android:name="android.intent.category.DEFAULT" />
        <category android:name="android.intent.category.BROWSABLE" />
        <data
            android:host="${newSubdomain}"
            android:scheme="https" />
      </intent-filter>
    </activity>
  </application>
</manifest>`);
    }
  }, []);

  const updateCode = (oneLinkURLStr: string) => {
    setOneLinkURL(oneLinkURLStr);
    if (oneLinkURLStr && checkOnelinkRegex(oneLinkURLStr)) {
      let newSubdomain = "";
      if (
        oneLinkURLStr?.startsWith("http://") ||
        oneLinkURLStr?.startsWith("https://")
      ) {
        newSubdomain = new URL(oneLinkURLStr)?.hostname;
      } else if (oneLinkRegexWithoutHttp.test(oneLinkURLStr)) {
        try {
          newSubdomain = new URL("https://" + oneLinkURLStr)?.hostname;
        } catch {
          setSnackbarOpen(true);
          setSnackbarMessage("Please fill a valid OneLink URL");
          return;
        }
      } else {
        return;
      }

      setCode1(`<?xml version="1.0" encoding="utf-8"?>
<manifest xmlns:android="http://schemas.android.com/apk/res/android"
    xmlns:tools="http://schemas.android.com/tools"
    package="${appId}">
  <application
    android:theme="@style/AppTheme">
    <activity
      android:name=".MainActivity"
      android:exported="true"
      android:launchMode="singleTask">
      <intent-filter android:autoVerify="true">
        <action android:name="android.intent.action.VIEW" />
        <category android:name="android.intent.category.DEFAULT" />
        <category android:name="android.intent.category.BROWSABLE" />
        <data
            android:host="${newSubdomain}"
            android:scheme="https" />
      </intent-filter>
    </activity>
  </application>
</manifest>`);
    }
  };

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      setAppId(state?.appID ?? "");
    }
  }, [location.state]);

  const handleClick = () => {
    const progressState: ISessionProps = {
      ...location.state,
      currentPage: location.state?.currentPage + 1,
    };

    if (!oneLinkURL || !checkOnelinkRegex(oneLinkURL)) {
      setSnackbarOpen(true);
      setSnackbarMessage("Please fill a valid OneLink URL");
      return;
    } else {
      localStorage.setItem(
        `oneLinkURL_${location.state?.os}_${location.state?.appID}`,
        JSON.stringify(oneLinkURL)
      );
      progressState.oneLinkURL = oneLinkURL;
    }
    amplitudeTrack_continue(location.state);

    navigate("/deeplink/android/prepare2", { state: progressState });
  };

  const steps = [
    {
      label: t("dl_6_ios_prerequisites_a"),
      component: <></>,
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_6_ios_prerequisites_d_title"),
      component: (
        <> 
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <FormControl
                error={!oneLinkURL || !checkOnelinkRegex(oneLinkURL)}
              >
                <TextField
                  required={true}
                  label={t("dl_4_ios_one_link_label_android")}
                  value={oneLinkURL}
                  inputProps={{ className: "amp-unmask" }}
                  onChange={(e) => {
                    updateCode(e.currentTarget.value);
                    amplitudeTrack_inputChange(
                      location.state,
                      InputType.TextField,
                      "oneLinkURL",
                      e.target.value
                    );
                  }}
                  placeholder={
                    "https://[Your-Domain.com]/[XXXX]/.... - for example: https://onelink-sim.com/ObO1/b081iy47"
                  }
                  variant="outlined"
                  size={notMobile ? "xl" : ""}
                  style={!notMobile ? { maxWidth: "200px" } : {}}
                />
                <FormHelperText id="component-error-text">
                  {t("startSDK_appParameters_android_required")}
                </FormHelperText>
              </FormControl>
            </Box>
            <AmplitudeLink
              href="https://support.appsflyer.com/hc/en-us/articles/208874366-OneLink-links-and-experiences"
              target="_blank"
              rel="noopener noreferrer"
              mx={2}
              mb={1}
            >
              {t("dl_4_ios_ask_marketer_link")}
            </AmplitudeLink>
          </Box>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page6_android_addIntentFilter"),
      component: (
        <>
          <Typography>
            {t("dl_page6_android_addIntentFilterInstructions")}
          </Typography>

          <br />
          {/* Code Snippet */}
          <DJCodeBlock
            code={code1}
            language="xml"
            showLineNumbers={true}
            highlight="11-18"
          />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          <Typography sx={{ mb: 2 }} variant="h1">
            {t("dl_page6_android_prepareAppTitle")}
          </Typography>
        </Box>
        <Typography variant="h3">{t("dl_6_ios_prerequisites")}</Typography>
        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={
                          activeStep === 1 &&
                          (!oneLinkURL || !checkOnelinkRegex(oneLinkURL))
                        }
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        >
          <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          disabled={!oneLinkURL || !checkOnelinkRegex(oneLinkURL)}
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(DL_prepareAndroid);
