import { useState, useEffect } from "react";
import { Button, Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import DJCodeBlock, { ICodeBlock } from "../../main/components/codeblock";
import {
  IProgress,
  ISessionProps,
} from "../../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { amplitudeTrack_continue } from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";

const InstallSDK = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = useState<IProgress[]>([]);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const state: ISessionProps = location.state as ISessionProps;
    setProgress(state?.progress);
  }, [location.state]);

  const handleClick = () => {
    const progressArr: IProgress[] = progress.slice();
    progressArr.push({
      id: "startsdk_permissions",
      text: "progress_startsdk_permissions",
      order: 3,
    });
    // Initialize progress with a copy of progressArr
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };

    amplitudeTrack_continue(location.state)

    navigate(`/startsdk/${location.state.os.toLowerCase()}/setpermissions`, {
      state: progressState,
    });
  };

  const code = `repositories {
  mavenCentral()
}`;

  const code2 = `dependencies { 
  // Get the latest version from https://mvnrepository.com/artifact/com.appsflyer/af-android-sdk 
  implementation 'com.appsflyer:af-android-sdk'
}`;

  const codeBlockArray: ICodeBlock[] = [
    {
      code: code,
      language: "swift",
      showLineNumbers: true,
    },
    {
      code: code2,
      language: "swift",
      showLineNumbers: true,
    },
  ];

  const steps = [
    {
      label: t("startSDK_InstallSDK_android_Step1"),
      component: (
        <>
          <Box mb={2}>
            <Typography>
              {t("startSDK_InstallSDK_android_DeclareRepositories")}
            </Typography>
          </Box>
          <DJCodeBlock {...codeBlockArray[0]}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("startSDK_InstallSDK_android_Step2"),
      component: (
        <>
          <Box display={"flex"} mb={2}>
            <Typography sx={{ marginRight: 0.5 }}>
              {t("startSDK_InstallSDK_android_AddDependenciesPart1") + " "}
              <AmplitudeLink
                target="_blank"
                href="https://mvnrepository.com/artifact/com.appsflyer/af-android-sdk"
              >
                {t("startSDK_InstallSDK_android_TheLatestSDK")}
              </AmplitudeLink>
              {" " + t("startSDK_InstallSDK_android_AddDependenciesPart2")}
            </Typography>
          </Box>
          <DJCodeBlock {...codeBlockArray[1]}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  return (
    <>
      <Container maxWidth={"lg"}>
        <Box padding={3} style={{ minHeight: "50vh" }}>
          <Typography variant="h1">
            {t("startSDK_InstallSDK_android_Title")}
          </Typography>
          <Typography variant="h3">
            {t("dl_15_ios_deep_link_link_use")}{" "}
            <AmplitudeLink
              target="_blank"
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/install-android-sdk#install-using-gradle`}
            >
              Gradle
            </AmplitudeLink>
          </Typography>

          <Stepper
            activeStep={activeStep}
            style={{ border: "none" }}
            orientation="vertical"
          >
            {steps.map(
              ({ label, component, shouldDisplayNavigationButtons }) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {component}
                    {shouldDisplayNavigationButtons && (
                      <Box sx={{ mt: 2 }}>
                        {activeStep !== 0 && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={handleBack}
                            // sx={{ mt: 2, mr: 1 }}
                          >
                            {t("general_Back")}
                          </Button>
                        )}
                        {activeStep !== steps.length - 1 && (
                          <Button
                            // sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            {activeStep === steps.length - 1
                              ? "Finish"
                              : t("general_Next")}
                          </Button>
                        )}
                      </Box>
                    )}
                  </StepContent>
                </Step>
              )
            )}
          </Stepper>
        </Box>
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent="space-between"
          alignSelf="end"
          justifySelf="end"
          marginTop={10}
          textAlign="end"
        >
          {" "}
          <Button
            variant="outlined"
            size="medium"
            color="secondary"
            style={{ marginRight: "5px" }}
            onClick={() => navigate(-1)}
          >
            {t("general_Back")}
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => handleClick()}
          >
            {t("general_Continue")}
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default transition(InstallSDK);
