/* eslint-disable */
import { useEffect, useState } from "react";
import { Box, Container, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IProgress,
  ISessionProps,
  OSType,
  SDKTestType,
  SessionStatus,
} from "../main/components/IProgress";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Typography, Note } from "@appsflyer/fe-ui-core";
import transition from "../main/components/transition";
import DJCodeBlock from "../main/components/codeblock";
import { getApiVer } from "../services/api";
import { amplitudeTrack_continue } from "../services/utils";
import AmplitudeLink from "../services/AmplitudeLink";

const Step4_Android = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const modifiedUrl = `POST:https://<<RANDOM-STRING>>-inapps.appsflyersdk.com/api/<<SDK_VERSION>>/${location.state.os}event?app_id=${location.state?.appID}`;
  const modifiedUrlSearch = `${location.state.os}event?app_id=${location.state?.appID}`;
  const code = `"event": "${location.state.eventName}",
"eventvalue":"{${(location.state.eventParams as Array<string>)
    .map((param) => `"${param}":<<PARAM_VALUE>>`)
    .join(",")}}"`;

  return (
    <>
      <>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h3">
            {t("ip_InAppResults_android_eventPreparedLabel")}
          </Typography>
          <span>
            {t("ip_InAppResults_android_searchLogs")}{" "}
            <code>preparing data:</code>{" "}
            {t("ip_InAppResults_android_holdsString")}
          </span>
          <div>
            <DJCodeBlock code={code} language="swift" showLineNumbers={true} />
          </div>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="h3">
            {t("ip_InAppResults_ios_eventSentLabel")}
          </Typography>
          <span>{t("ip_InAppResults_android_holdsStringsLabel")} </span>
          <Box sx={{ mb: 1 }}>
            <DJCodeBlock
              code={modifiedUrlSearch}
              language="swift"
              showLineNumbers={true}
            />
          </Box>
          <span>{t("ip_InAppResults_android_holdsStringsLabel_example")} </span>
          <Box>
            <DJCodeBlock
              code={modifiedUrl}
              language="swift"
              showLineNumbers={true}
            />
          </Box>
        </Box>
      </>
    </>
  );
};

const Step4_iOS = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const modifiedUrl = `https://<<RANDOM_STRING>>-inapps.appsflyersdk.com/api/<<SDK_VERSION>>/iosevent?app_id=${location.state?.appId}`;
  const modifiedUrlSearch = `${location.state.os}event?app_id=${location.state?.appID}`;
  const code = `"event": "${location.state.eventName}",
"eventvalue":"{${(location.state.eventParams as Array<string>)
    .map((param) => `"${param}":<<PARAM_VALUE>>`)
    .join(",")}}"`;

  return (
    <>
      <>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h3">
            {t("ip_InAppResults_android_eventPreparedLabel")}
          </Typography>
          <span>
            {t("ip_InAppResults_android_searchLogs")}{" "}
            <code>preparing data:</code>{" "}
            {t("ip_InAppResults_android_holdsString")}
          </span>
          <div>
            <DJCodeBlock code={code} language="swift" showLineNumbers={true} />
          </div>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="h3">
            {t("ip_InAppResults_ios_eventSentLabel")}
          </Typography>
          <span>{t("ip_InAppResults_android_holdsStringsLabel")} </span>
          <Box sx={{ mb: 1 }}>
            <DJCodeBlock
              code={modifiedUrlSearch}
              language="swift"
              showLineNumbers={true}
            />
          </Box>
          <span>{t("ip_InAppResults_android_holdsStringsLabel_example")} </span>
          <Box>
            <DJCodeBlock
              code={modifiedUrl}
              language="swift"
              showLineNumbers={true}
            />
          </Box>
        </Box>
      </>
    </>
  );
};

const InApp_TestSDKIntegration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function finishProcess() {
    const progressState: ISessionProps = {
      ...location.state,
      currentPage: location.state?.currentPage + 1,
      sessionStatus: SessionStatus.Success,
      completionStatus: true,
    };

    // send gtag for manual testing success
    gtag("event", "manual_test_success_fixed", {
      app_name: "devjourney",
      screen_name: "TestInappIntegration",
    });
    amplitudeTrack_continue(location.state);

    navigate("/finish", { state: progressState });
  }

  const handleClick = () => {
    const progressArr: IProgress[] = location.state?.progress.slice();
    progressArr.push({
      id: "inapp_testevents",
      text: "progress_getres",
      order: 6,
    });

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };

    if (progressState.isEvgenTesting) {
      navigate(`/inapp/${location.state?.os.toLowerCase()}/evgenevents`, {
        state: progressState,
      });
    } else {
      navigate(`/inapp/${location.state?.os.toLowerCase()}/eventstestconsole`, {
        state: progressState,
      });
    }
  };

  const steps = [
    {
      label: t("ip_TestInAppIntegration_ios_stepOneHeading"),
      component: (
        <>
          {location.state?.sdkTestType === SDKTestType.Manual ? (
            <>
              <ul>
                <li>
                  <Typography variant="body1">
                    {t("ip_TestInAppIntegration_ios_stepOneContent")}
                    <br />
                    {t("ip_TestInAppIntegration_ios_stepOneContent_b")}
                    <b>{t("ip_TestInAppIntegration_ios_stepOneContent_c")}</b>
                    {t("ip_TestInAppIntegration_ios_stepOneContent_d")}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>
                      {t("startSDK_TestSDKIntegration_android_Step2") + ": "}
                    </span>
                    <AmplitudeLink
                      target="_blank"
                      href={
                        location.state?.os === OSType.Android
                          ? `https://${
                              location.state.lang != "en"
                                ? location.state.lang + "."
                                : ""
                            }dev.appsflyer.com/hc/docs/integrate-android-sdk#enabling-debug-mode`
                          : `https://${
                              location.state.lang != "en"
                                ? location.state.lang + "."
                                : ""
                            }dev.appsflyer.com/hc/docs/integrate-ios-sdk#enabling-debug-mode`
                      }
                    >
                      {t("startSDK_TestSDKIntegration_android_EnableDebugMode")}
                    </AmplitudeLink>
                  </Typography>
                </li>
              </ul>
            </>
          ) : (
            <>
              <Typography variant="body1">
                {t("ip_TestInAppIntegration_ios_stepOneContent")}
                <br />
                {t("ip_TestInAppIntegration_ios_stepOneContent_b")}
                <b>{t("ip_TestInAppIntegration_ios_stepOneContent_c")}</b>
                {t("ip_TestInAppIntegration_ios_stepOneContent_d")}
              </Typography>
            </>
          )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("ip_TestInAppIntegration_ios_stepThreeHeading"),
      component: (
        <>
          <Typography>
            {t("ip_TestInAppIntegration_ios_stepThreeContent")}
            <b>{t("ip_TestInAppIntegration_ios_stepThreeContent2")}</b>
          </Typography>
          {location.state?.os === OSType.iOS &&
            location.state?.deviceType === "IDFA" && (
              <Box mt={2}>
                <Note>
                  <Typography style={{ display: "inline" }}>
                    {t("testConsole_TestType_myDeviceID_iosAtt")}
                  </Typography>
                </Note>
              </Box>
            )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  if (location.state?.sdkTestType === SDKTestType.Manual) {
    steps.push({
      label: t("ip_InAppResults_ios_stepFourHeading"),
      component: (
        <>
          {location.state?.os === OSType.Android ? (
            <Step4_Android />
          ) : (
            <Step4_iOS />
          )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    });
  }

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Typography variant="h1">
          {t("ip_TestInAppIntegration_ios_testSDKIntegrationHeading")}
        </Typography>
        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        {location.state?.sdkTestType === SDKTestType.Manual ? (
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => finishProcess()}
            disabled={activeStep != steps.length - 1}
          >
            {t("ip_InAppResults_android_doneButton")}
          </Button>
        ) : (
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => handleClick()}
            disabled={activeStep != steps.length - 1}
          >
            {location.state?.sdkTestType === SDKTestType.Automated
              ? t("testconsole_getRes")
              : t("general_Continue")}
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default transition(InApp_TestSDKIntegration);
