import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Box, Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IProgress,
  ISessionProps,
  OSType,
  SDKTestType,
} from "../main/components/IProgress";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Typography, Note } from "@appsflyer/fe-ui-core";
import transition from "../main/components/transition";
import { amplitudeTrack_continue } from "../services/utils";
import AmplitudeLink from "../services/AmplitudeLink";
import StartFunctionExplanation from "../main/components/StartFunctionExp";

const TestSDKIntegration = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [osType] = useState<OSType>(location.state?.os);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {}, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClick = () => {
    // Initialize progress with a copy of progressArr

    if (location.state?.sdkTestType === SDKTestType.Manual) {
      const progressState: ISessionProps = {
        ...location.state,
        currentPage: location.state?.currentPage + 1,
      };

      amplitudeTrack_continue(location.state);

      navigate(`/startsdk/${osType.toLowerCase()}/testsdkuid`, {
        state: progressState,
      });
    } else {
      const progressArr: IProgress[] = location.state?.progress.slice();
      progressArr.push({
        id: "startsdk_testevents",
        text: "progress_startsdk_testevents",
        order: 8,
      });

      const progressState: ISessionProps = {
        ...location.state,
        progress: progressArr,
        currentPage: location.state?.currentPage + 1,
      };

      amplitudeTrack_continue(location.state);

      navigate(`/startsdk/${osType.toLowerCase()}/eventstestconsole`, {
        state: progressState,
      });
    }
  };

  const steps = [
    {
      label: t("startSDK_TestSDKIntegration_android_Step1"),
      component: (
        <>
          {location.state?.sdkTestType === SDKTestType.Manual ? (
            <>
              <br />
              <Note>
                <br />
                <b>
                  {t("startSDK_TestSDKIntegration_android_Step1_required")}
                </b>{" "}
                {t("startSDK_TestSDKIntegration_android_Step1_required_no_sim")}
              </Note>
              <ul>
                <li>
                  <Typography variant="body1">
                    {t("startSDK_TestSDKIntegration_android_Step1Description")}{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {t("startSDK_TestSDKIntegration_android_Not")}
                    </span>{" "}
                    {t("startSDK_TestSDKIntegration_android_InstalledOnDevice")}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>
                      {t("startSDK_TestSDKIntegration_android_Step2") + ": "}
                    </span>
                    <AmplitudeLink
                      target="_blank"
                      href={
                        osType === OSType.Android
                          ? `https://${
                              location.state.lang != "en"
                                ? location.state.lang + "."
                                : ""
                            }dev.appsflyer.com/hc/docs/integrate-android-sdk#enabling-debug-mode`
                          : `https://${
                              location.state.lang != "en"
                                ? location.state.lang + "."
                                : ""
                            }dev.appsflyer.com/hc/docs/integrate-ios-sdk#enabling-debug-mode`
                      }
                    >
                      {t("startSDK_TestSDKIntegration_android_EnableDebugMode")}
                    </AmplitudeLink>
                  </Typography>
                </li>
              </ul>
            </>
          ) : (
            <>
              <Typography variant="body1">
                {t("startSDK_TestSDKIntegration_android_Step1Description")}{" "}
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {t("startSDK_TestSDKIntegration_android_Not")}
                </span>{" "}
                {t("startSDK_TestSDKIntegration_android_InstalledOnDevice")}
              </Typography>
            </>
          )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("startSDK_TestSDKIntegration_android_Step4"),
      component: (
        <>
          <Typography>
            {t("testConsole_TestResults_TroubleshootingTips_launch")}
            <code>
              start()
              <StartFunctionExplanation />
            </code>
            <span style={{ marginLeft: 18 }}>
              {t("testConsole_TestResults_TroubleshootingTips_start_b")}
            </span>
          </Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  if (location.state.os === OSType.iOS) {
    steps.push({
      label:
        location.state.sdkTestType === SDKTestType.Manual
          ? t("startSDK_TestSDKIntegration_ios_Step5")
          : t("testConsole_TestType_myDeviceID_iosAtt"),
      component: (
        <>
          <img
            style={{ maxWidth: 300 }}
            src={process.env.PUBLIC_URL + "/images/ATT_screen.png"}
            alt="Debug Logs"
          />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    });
  }

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          {t("startSDK_TestSDKIntegration_android_TestSDKIntegration")}
        </Typography>
        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
          disabled={activeStep != steps.length - 1}
        >
          {location.state?.sdkTestType === SDKTestType.Automated
            ? t("testconsole_getRes")
            : t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(TestSDKIntegration);
