import { useTranslation } from "react-i18next";
import { Button, Box, Container } from "@mui/material";
import {
  IProgress,
  ISessionProps,
  IntegrationPathType,
  SDKTestType,
  SessionStatus,
} from "../components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { InteractiveCard, Typography } from "@appsflyer/fe-ui-core";
import CardContent from "@mui/material/CardContent";
import transition from "../components/transition";
import { DeeplinkType } from "../../deeplinking/DeferredOrDirect";
import { amplitudeTrack_continue } from "../../services/utils";
// import StarIcon from "@mui/icons-material/Star";

const TestingTypePath = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const toTestConsole = () => {
    // Initialize progress with a copy of progressArr
    const progressArr: IProgress[] = location.state?.progress.slice();

    if (location.state?.path === IntegrationPathType.StartSDK) {
      progressArr.push({
        id: "startsdk_register",
        text: "progress_startsdk_register",
        order: 6,
      });
    } else if (location.state?.path === IntegrationPathType.InApp) {
      progressArr.push({
        id: "inapp_register",
        text: "progress_inapp_register",
        order: 5,
      });
    } else if (location.state?.path === IntegrationPathType.DeepLink) {
      progressArr.push({
        id: "dl_register",
        text: "progress_dl_register",
        order: 5,
      });
    }

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      sdkTestType: SDKTestType.Automated,
      sessionStatus: SessionStatus.IncompleteTesting,
      currentPage: location.state?.currentPage + 1,
    };

    amplitudeTrack_continue(location.state, {
      testType: SDKTestType.Automated,
    });

    if (location.state?.path === IntegrationPathType.StartSDK) {
      navigate(`/startsdk/${progressState.os}/testconsole`, {
        state: progressState,
      });
    } else if (location.state?.path === IntegrationPathType.InApp) {
      navigate(`/inapp/${progressState.os}/testconsole`, {
        state: progressState,
      });
    } else if (location.state?.path === IntegrationPathType.DeepLink) {
      navigate(`/deeplink/${progressState.os}/testconsole`, {
        state: progressState,
      });
    }
  };

  const toManual = () => {
    // Initialize progress with a copy of progressArr
    const progressArr: IProgress[] = location.state?.progress.slice();

    if (location.state?.path === IntegrationPathType.StartSDK) {
      progressArr.push({
        id: "startsdk_testintegration_manual",
        text: "progress_startsdk_testintegration_manual",
        order: 7,
      });
    } else if (location.state?.path === IntegrationPathType.InApp) {
      progressArr.push({
        id: "inapp_testintegration_manual",
        text: "progress_inapp_testintegration_manual",
        order: 4,
      });
    } else if (location.state?.path === IntegrationPathType.DeepLink) {
      if (location.state?.deeplinkType == DeeplinkType.Deferred) {
        if (!progressArr.some((prog) => prog.id === "dl_testDeferred_manual")) {
          progressArr.push({
            id: "dl_testDeferred_manual",
            text: "progress_dl_testDeferred_manual",
            order: 6,
          });
        }
      } else {
        if (!progressArr.some((prog) => prog.id === "dl_testDirect_manual")) {
          progressArr.push({
            id: "dl_testDirect_manual",
            text: "progress_dl_testDirect_manual",
            order: 7,
          });
        }
      }
    }

    amplitudeTrack_continue(location.state, { testType: SDKTestType.Manual });

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      sdkTestType: SDKTestType.Manual,
      sessionStatus: SessionStatus.IncompleteTesting,
      currentPage: location.state?.currentPage + 1,
    };

    if (location.state?.path === IntegrationPathType.StartSDK) {
      navigate(`/startsdk/${progressState.os}/testsdkintegration`, {
        state: progressState,
      });
    } else if (location.state?.path === IntegrationPathType.InApp) {
      if (location.state.isEvgenTesting) {
        navigate(`/inapp/${progressState.os}/evgeneventsmanual`, {
          state: progressState,
        });
      } else {
        navigate(`/inapp/${progressState.os}/testinappintegration`, {
          state: progressState,
        });
      }
    } else if (location.state?.path === IntegrationPathType.DeepLink) {
      if (location.state?.deeplinkType == DeeplinkType.Deferred) {
        navigate(`/deeplink/${progressState.os}/testdeferreddl`, {
          state: progressState,
        });
      } else {
        navigate(`/deeplink/${progressState.os}/testDirect`, {
          state: progressState,
        });
      }
    }
  };

  const cardStyle = {
    width: 240,
    height: 280,
    margin: "0 10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    paddingTop: 0,
    padding: 15,
  };

  return (
    <Container maxWidth={"lg"}>
      <Box
        textAlign={"center"}
        marginTop={5}
        marginBottom={5}
        padding={5}
        style={{ minHeight: "50vh" }}
      >
        {location.state?.path === IntegrationPathType.StartSDK && (
          <Typography variant="h1">
            {t("startSDK_Step5_android_TestSDKIntegration")}
          </Typography>
        )}
        {location.state?.path === IntegrationPathType.InApp && (
          <Typography variant="h1">
            {t("ip_InAppResults_android_testSDKIntegrationHeading")}
          </Typography>
        )}
        {location.state?.path === IntegrationPathType.DeepLink && (
          <Typography variant="h1">
            {t("deeplink_testSDK_all_testResult_heading")}
          </Typography>
        )}
        <Typography variant="h2">
          {t("testConsole_TestType_testtype")}
        </Typography>
        <Box
          display={"flex"}
          sx={{ mt: 5, flexDirection: { xs: "column", md: "row" } }}
          gap={1}
          justifyContent={"center"}
        >
          <InteractiveCard style={cardStyle} onClick={() => toManual()}>
            <CardContent style={{ textAlign: "left" }}>
              <Box height={30}></Box>
              <Typography variant="h3">
                {t("testConsole_TestType_manualTest")}
              </Typography>
              <Typography variant="body1">
                {t("testConsole_TestType_manualTest_desc")}
              </Typography>
              <br />
              <Typography style={{ fontWeight: "bold" }}>
                {t("testConsole_TestType_manualTest_desc_b")}
              </Typography>
              <ul style={{ padding: 0, margin: 5 }}>
                <li>
                  <Typography variant="body2">
                    <b>{t("testConsole_TestType_manualTest_desc_c")}</b>
                  </Typography>
                </li>
              </ul>
            </CardContent>
          </InteractiveCard>
          <InteractiveCard style={cardStyle} onClick={() => toTestConsole()}>
            <CardContent style={{ textAlign: "left" }}>
              <Box height={30}>
                {/* <Box gap={1} display={"flex"}>
                  <StarIcon style={{ color: "#ffcf23" }} />
                  <Typography style={{ fontStyle: "italic", fontWeight: 500 }}>
                    {t("general_recommended")}
                  </Typography>
                </Box> */}
                {/* <hr /> */}
              </Box>
              <Typography variant="h3">
                {t("testConsole_TestType_autoTest")}
              </Typography>
              <Typography variant="body1">
                {t("testConsole_TestType_autoTest_desc")}
              </Typography>
              <br />
              <br />
              <Typography style={{ fontWeight: "bold" }}>
                {t("testConsole_TestType_manualTest_desc_b")}
              </Typography>
              <ul style={{ padding: 0, margin: 5 }}>
                <li>
                  <Typography variant="body2">
                    <b>API v2 Token </b>
                    {t("testConsole_TestType_autoTest_desc_c")}
                  </Typography>
                </li>
              </ul>
              <br />
            </CardContent>
          </InteractiveCard>
        </Box>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(TestingTypePath);
