import { SyntheticEvent, useEffect, useState } from "react";
import {
  Button,
  Alert,
  Box,
  Container,
  FormHelperText,
  FormControl,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useTranslation } from "react-i18next";
import { ISessionProps, OSType } from "../components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { getGCD } from "../../services/api";
import { TextField, Typography, LoadingButton } from "@appsflyer/fe-ui-core";
import transition from "../components/transition";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  amplitudeTrack_continue,
  amplitudeTrack_inputChange,
  amplitudeTrack_login,
  InputType,
  sendGtagOnce,
} from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";
import AndroidOutlinedIcon from "@mui/icons-material/AndroidOutlined";
import AppleIcon from "@mui/icons-material/Apple";

const AppParameters = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [appId, setAppId] = useState("");
  const [devKey, setDevKey] = useState("");
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarMessageExtra, setSnackbarMessageExtra] = useState("");

  useEffect(() => {
    const state: ISessionProps = location.state as ISessionProps;
    state?.appID && setAppId(state?.appID);
  }, [location.state]);

  useEffect(() => {
    if (location.state?.os === OSType.Android) {
      const lsAndroidAppId = localStorage.getItem("appId_android");
      if (lsAndroidAppId) {
        setAppId(JSON.parse(lsAndroidAppId));
        localStorage.setItem("appId", lsAndroidAppId);
      }
    } else if (location.state?.os === OSType.iOS) {
      const lsIosAppId = localStorage.getItem("appId_ios");
      if (lsIosAppId) {
        setAppId(JSON.parse(lsIosAppId));
        localStorage.setItem("appId", lsIosAppId);
      }
    }

    const lsDevKey = localStorage.getItem("devKey");
    if (lsDevKey) {
      setDevKey(JSON.parse(lsDevKey));
    }
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!appId || !devKey) return;

    // Initialize progress with a copy of progressArr
    const progressState: ISessionProps = {
      ...location.state,
      currentPage: location.state?.currentPage + 1,
      appID: appId,
    };
    setLoading(true);

    getGCD("0", devKey, appId)
      ?.then((res) => {
        setLoading(false);
        setSnackbarMessageExtra("");
        if (res.data.status_code === 404) {
          // adding appID and devkey to localStorage
          localStorage.setItem("devKey", JSON.stringify(devKey));
          localStorage.setItem("appId", JSON.stringify(appId));
          if (location.state?.os === OSType.Android) {
            localStorage.setItem("appId_android", JSON.stringify(appId));
            localStorage.setItem("appId", JSON.stringify(appId));
          } else if (location.state?.os === OSType.iOS) {
            const iosIdwithPrefix =
              appId.indexOf("id") > -1 ? appId : `id${appId}`;
            progressState.appID = iosIdwithPrefix;
            localStorage.setItem("appId_ios", JSON.stringify(iosIdwithPrefix));
            localStorage.setItem("appId", JSON.stringify(iosIdwithPrefix));
          }

          sendGtagOnce("login", "AppParameters", location.state.sessionId);
          amplitudeTrack_continue(location.state);
          amplitudeTrack_login(location.state, appId);

          navigate(`/integrationPath${location.search}`, {
            state: progressState,
          });
        }
        // else show mui toast of invalid app params
        else if (res.data.status_code === 400) {
          const errorMessage = res.data.error_reason;
          if (errorMessage === "App ID is incorrect") {
            setSnackbarMessage(errorMessage);
            setSnackbarMessageExtra(t("startSDK_appParameters_checkAppId"));
          } else {
            setSnackbarMessage(errorMessage);
          }
          setSnackbarOpen(true);
          console.log("errorMessage", errorMessage);
        } else if (res.data.status_code === 403) {
          setSnackbarOpen(true);
          setSnackbarMessage(
            "Unfortunately we do not support Zero Package Plan"
          );
        } else {
          console.error("Unexpected response:", res);
        }
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage("An error occurred. Please try again later.");
        console.error(err);
      });
  };

  const handleCloseSnackbar = (
    event: SyntheticEvent<Element, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // const devkeyRegex = /^[a-zA-Z][a-zA-Z0-9+\-.]*:/;
  const devkeyRegex = /^\w{22,}/;

  const checkAppleAppID = (appID: string) => {
    // check if app id contains numbers only or starts with ID
    if (/^\d+$/.test(appID)) {
      return true;
    } else if (appID.indexOf("id") === 0) {
      if (/^\d+$/.test(appID.replace("id", ""))) {
        return true;
      }
    }
    return false;
  };

  const checkAndroidBundle = (appID: string) => {
    // check if app id contains numbers only or starts with ID
    return (
      /^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/.test(appID) ||
      /^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*-[A-Za-z][A-Za-z\d_]*$/.test(
        appID
      )
    );
  };

  const checkInvalidAppID = () => {
    if (!appId) return true;

    // checking if the app id is invalid for ios
    if (location.state?.os === OSType.iOS && !checkAppleAppID(appId))
      return true;

    // checking if the app id is invalid for android
    if (location.state?.os === OSType.Android && !checkAndroidBundle(appId))
      return true;

    return false;
  };

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box sx={{ mb: 4 }} display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h1">
            {t("startSDK_appParameters_android_EnterDetails")}
          </Typography>
        </Box>

        <Box
          sx={{ flexDirection: { md: "row", xs: "column" } }}
          display={"flex"}
          alignItems={"center"}
          padding={0.5}
        >
          <FormControl error={checkInvalidAppID()}>
            <Box display={"flex"} alignItems={"flex-end"}>
              <TextField
                required={true}
                id="appId"
                value={appId}
                inputProps={{ className: "amp-unmask" }}
                placeholder={
                  location.state?.os === OSType.iOS
                    ? "id6479639694, 6479639694"
                    : location.state?.os === OSType.Android
                    ? "com.appsflyer.appexample"
                    : ""
                }
                error={checkInvalidAppID()}
                onChange={(e) => {
                  setAppId(e.target.value);
                  amplitudeTrack_inputChange(
                    location.state,
                    InputType.TextField,
                    "appId",
                    e.target.value
                  );
                }}
                label={
                  location.state.os === OSType.Android ? (
                    <Typography>
                      {"Android " + t("startSDK_appParameters_android_AppID")}
                      <AndroidOutlinedIcon sx={{ height: ".75em"}} />
                    </Typography>
                  ) : (
                    <Typography>
                      {"iOS " + t("startSDK_appParameters_android_AppID")}
                      <AppleIcon sx={{ height: ".75em"}} />
                    </Typography>
                  )
                }
                variant="outlined"
              />
              <Typography>
                <AmplitudeLink
                  my={5}
                  mx={2}
                  target="_blank"
                  href={
                    location.state?.os === OSType.Android
                      ? "https://support.appsflyer.com/hc/en-us/articles/207377436-Adding-an-app-to-AppsFlyer#android-androidtv-fire"
                      : "https://support.appsflyer.com/hc/en-us/articles/207377436-Adding-an-app-to-AppsFlyer#ios-tvos-macos"
                  }
                >
                  {t("startSDK_appParameters_android_ObtainAppID")}
                  <OpenInNewIcon sx={{ fontSize: 13, mx: 0.5 }} />
                </AmplitudeLink>
              </Typography>
            </Box>

            <FormHelperText id="component-error-text">
              {t("startSDK_appParameters_android_required")}
            </FormHelperText>
            {location.state?.os === OSType.iOS && (
              <FormHelperText id="component-error-text">
                {t("startSDK_appParameters_ios_example") + " "} id6479639694,
                6479639694
              </FormHelperText>
            )}
            {location.state?.os === OSType.Android && (
              <FormHelperText id="component-error-text">
                {t("startSDK_appParameters_android_example") + " "}{" "}
                com.appsflyer.appexample
              </FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box
          sx={{ mt: 2, flexDirection: { md: "row", xs: "column" } }}
          display={"flex"}
          alignItems={"center"}
          padding={0.5}
        >
          <FormControl error={!devKey || !devkeyRegex.test(devKey)}>
            <Box display={"flex"} alignItems={"flex-end"}>
              <TextField
                required={true}
                value={devKey}
                placeholder={"DvwhQxKLQgvTrq48piQfSb"}
                onChange={(e) => {
                  setDevKey(e.target.value);
                  amplitudeTrack_inputChange(
                    location.state,
                    InputType.TextField,
                    "devKey",
                    e.target.value
                  );
                }}
                id="devKey"
                label={t("startSDK_appParameters_android_DevKey")}
                variant="outlined"
              />
              <Typography>
                <AmplitudeLink
                  target="_blank"
                  href="https://support.appsflyer.com/hc/en-us/articles/207032066-Basic-SDK-integration-guide#retrieve-the-dev-key"
                  my={5}
                  mx={2}
                >
                  {t("startSDK_appParameters_android_ObtainDevKey")}
                  <OpenInNewIcon sx={{ fontSize: 13, mx: 0.5 }} />
                </AmplitudeLink>
              </Typography>
            </Box>
            <FormHelperText id="component-error-text">
              {t("startSDK_appParameters_android_required")}
            </FormHelperText>
          </FormControl>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          style={{ position: "sticky" }}
          sx={{ mt: 4 }}
        >
          <Alert
            variant="filled"
            onClose={handleCloseSnackbar}
            severity="error"
          >
            {snackbarMessage}
            {snackbarMessageExtra ? (
              <>
                <br />
                {snackbarMessageExtra}
              </>
            ) : (
              <></>
            )}
          </Alert>
        </Snackbar>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginLeft={30}
        ></Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
        width={"100%"}
      >
        {" "}
        <Button
          variant="outlined"
          color="secondary"
          size="medium"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
          disabled={loading}
        >
          {t("general_Back")}{" "}
        </Button>
        <FormControl>
          <LoadingButton
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            onClick={(e) => handleClick(e)}
            disabled={loading || !appId || !devKey || checkInvalidAppID()}
            loading={loading}
            aria-label={t("general_Continue")}
          >
            {t("general_Continue")}
          </LoadingButton>
        </FormControl>
      </Box>
    </Container>
  );
};

export default transition(AppParameters);
