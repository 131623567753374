import { ChangeEvent, useEffect, useState } from "react";
import { Box, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { useTranslation } from "react-i18next";
import { iosParameter } from "./ios/arrays";
import { androidParams } from "./android/arrays";
import { useLocation } from "react-router-dom";
import { OSType } from "../main/components/IProgress";
import { TextField, Select } from "@appsflyer/fe-ui-core";
import { amplitudeTrack_inputChange, InputType } from "../services/utils";

type SendInappEventParamFormProps = {
  onEventParamChange?: (newName: string) => void;
  eventParam: string;
};

export type IOption = {
  value: string;
  label: string;
};

const SendInappEventParamForm: React.FC<SendInappEventParamFormProps> = (
  props: SendInappEventParamFormProps
) => {
  // Radio group for the second section
  const { t } = useTranslation();
  const location = useLocation();

  const [selectedParamType, setSelectedParamType] = useState("Predefined");
  const [isCustomEventParamName, setIsCustomEventParamName] = useState(false);
  const [eventParamName, setEventParamName] = useState<IOption>(null);
  const [eventCustomParamName, setEventCustomParamName] = useState<string>("");

  const handleCustomInputChange2 = (event: ChangeEvent<HTMLInputElement>) => {
    amplitudeTrack_inputChange(
      location.state,
      InputType.TextField,
      "eventCustomParamName",
      event.target.value
    );
    setEventCustomParamName(event.target.value);
  };

  useEffect(() => {
    const paramValue = isCustomEventParamName
      ? eventCustomParamName
      : eventParamName?.value;
    if (paramValue || isCustomEventParamName) props.onEventParamChange?.(paramValue);
  }, [eventParamName, eventCustomParamName, isCustomEventParamName]);

  const handleChangeSelectedParamType = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "isCustomParam",
      event.target.value
    );
    setIsCustomEventParamName(value === "custom");
    setSelectedParamType(value);
  };

  const options: IOption[] =
    location.state.os == OSType.iOS
      ? iosParameter.map((event) => ({
          value: event["ios_constant_name"],
          label: event["event_parameter_name"],
        }))
      : androidParams.map((event) => ({
          value: event["android_constant_name"],
          label: event["event_parameter_name"],
        }));

  return (
    <>
      <Box>
        <Box mt={2}>
          <RadioGroup
            row
            value={selectedParamType}
            onChange={handleChangeSelectedParamType}
          >
            <FormControlLabel
              control={<Radio />}
              label={t("ip_SendInApp_ios_predefined")}
              value={"Predefined"}
            />
            <Select
              id="event-name-2"
              variant="outlined"
              size={"medium"}
              defaultValue={t("ip_SendInApp_ios_chooseOption")}
              placeholder={t("ip_SendInApp_ios_chooseOption")}
              value={eventParamName}
              onChange={(e) => {
                setEventParamName(e);
                amplitudeTrack_inputChange(
                  location.state,
                  InputType.Select,
                  "eventParamName",
                  e
                );
              }}
              disabled={isCustomEventParamName}
              options={options}
            ></Select>
            <FormControlLabel
              sx={{ mx: 2 }}
              control={<Radio />}
              label={t("ip_SendInApp_ios_custom")}
              value={"custom"}
            />
            <TextField
              variant="outlined"
              id="custom2"
              value={eventCustomParamName}
              inputProps={{ className: "amp-unmask" }}
              onChange={handleCustomInputChange2}
              disabled={!isCustomEventParamName}
            />
          </RadioGroup>
        </Box>
      </Box>
    </>
  );
};

export default SendInappEventParamForm;
