import { useState, useEffect } from "react";
import { Button, Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import DJCodeBlock, { ICodeBlock } from "../../main/components/codeblock";
import {
  IProgress,
  ISessionProps,
} from "../../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import { amplitudeTrack_continue } from "../../services/utils";

const ReferrerPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = useState<IProgress[]>([]);

  useEffect(() => {
    const state: ISessionProps = location.state as ISessionProps;
    setProgress(state?.progress);
  }, [location.state]);

  const handleClick = () => {
    const progressArr: IProgress[] = progress.slice();
    progressArr.push({
      id: "startsdk_startSDK",
      text: "progress_startsdk_startSDK",
      order: 5,
    });
    // Initialize progress with a copy of progressArr
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };
    amplitudeTrack_continue(location.state)

    navigate(`/startsdk/${location.state.os.toLowerCase()}/startsdk`, {
      state: progressState,
    });
  };

  const code = `dependencies {
    // ...
    implementation "com.android.installreferrer:installreferrer:2.2"
}`;

  const codeProps: ICodeBlock = {
    code: code,
    language: "swift",
    showLineNumbers: true,
  };

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box display={"flex"}>
          <Typography variant="h1" fontWeight={"bold"}>
            {t("startSDK_ReferrerPage_android_Optional")}{" "}
            {t("startSDK_ReferrerPage_android_AddingStoreReferrerLibraries")}
          </Typography>
        </Box>

        <Typography variant="h2" fontWeight={"bold"} mt={5}>
          {t("startSDK_ReferrerPage_android_AddReferrer")}
        </Typography>
        <Typography variant="body1" mb={2}>
          {t("startSDK_ReferrerPage_android_SupportedLibraries")}
          <br />
          {t("startSDK_ReferrerPage_android_OnlyAddDependency")} ({t("dl_android_prepare3_prod_step1_text")}<code>build.gradle (Module :app)</code>)
        </Typography>
        <br />
        <DJCodeBlock {...codeProps}></DJCodeBlock>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(ReferrerPage);
