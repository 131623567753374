import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Alert, Box, Container } from "@mui/material";
import { IProgress, ISessionProps, OSType } from "../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { TextField, Typography } from "@appsflyer/fe-ui-core";
import transition from "../main/components/transition";
import { amplitudeTrack_continue, amplitudeTrack_inputChange, InputType } from "../services/utils";

const TestSDKUID = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [installUID, setInstallUID] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (!installUID) {
      setIsOpen(true);
      return;
    }

    const progressArr: IProgress[] = location.state?.progress.slice();
      progressArr.push({
        id: "startsdk_testevents_manual",
        text: "progress_startsdk_testevents",
        order: 8,
      });

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
      installUID,
    };
    amplitudeTrack_continue(location.state);

    navigate(`/startsdk/${location.state.os}/testresults`, { state: progressState });
  };

  const uidRegexAndroid = /^[0-9]{13}[-][0-9]{18,20}$/;
  const uidRegexiOS = /^[0-9]{13}[-][0-9]{6,8}$/;
  const uidRegex = location.state.os === OSType.Android ? uidRegexAndroid : uidRegexiOS;

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Typography variant="h1">
          {t("startSDK_Step5_android_TestSDKIntegration")}
        </Typography>
        <Typography variant="h2">
          {t("startSDK_Step5_android_Step5")}
        </Typography>

        {location.state.os === OSType.Android && (
          <Typography variant="body1">
            {t("startSDK_Step6_android_Step6Description")}
            <code>AppsFlyer_6</code>
          </Typography>
        )}
        <Typography variant="body1">
          {t("startSDK_Step5_android_Step5Description")}
          {location.state.os === OSType.iOS ? (
            <code>conversions.appsflyersdk</code>
          ) : (
            <code>CONVERSION-</code>
          )}
          {t("startSDK_Step5_android_Step5Description_b")}
        </Typography>
        <br />
        <img
          style={{ maxWidth: 800 }}
          src={
            process.env.PUBLIC_URL + location.state.os === OSType.Android
              ? "/images/debugLogsAndroid.png"
              : "/images/debugLogsiOS.png"
          }
          alt="Debug Logs"
        />
        <br></br>
        <br></br>
        <TextField
          onChange={(e) => {
            setInstallUID(e.target.value);
            amplitudeTrack_inputChange(
              location.state,
              InputType.TextField,
              "installUID",
              e.target.value
            );
          }}
          id="outlined-basic"
          label={t("startSDK_Step5_android_InstallUIDLabel") + "*"}
          variant="outlined"
          inputProps={{ maxLength: 34, className: "amp-unmask" }}
          placeholder={location.state.os === OSType.Android ? "1701782359632-3255642273354660841" : "1701782359632-3255642"}
          error={!installUID || !uidRegex.test(installUID)}
        />

        <Snackbar
          message={t("startSDK_Step5_android_InstallUIDMissing")}
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <Alert variant="filled" severity="error">
            {t("startSDK_Step5_android_InstallUIDMissing")}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={!installUID || !uidRegex.test(installUID)}
          onClick={() => handleClick()}
        >
          {t("startSDK_Step5_android_GetTestResults")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(TestSDKUID);
